import React from "react"
import Img from "gatsby-image"
import {
  Typography,
  Grid,
  makeStyles,
  Card,
  CardContent,
  CardMedia,
} from "@material-ui/core"
import { useStaticQuery, graphql } from "gatsby"
import Lottie from "react-lottie"
import analysisData from "../images/approach/animations/analysis.json"
import developmentData from "../images/approach/animations/development.json"
import iterationData from "../images/approach/animations/iteration.json"

const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: "20px",
  },
  text: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  contentContainer: {
    paddingTop: "20px",
    paddingBottom: "20px",
  },
  toolsContainer: {
    paddingTop: "20px",
  },
  header: {
    fontFamily: "Patua One",
  },
})

// 'IG242665'
const Approach = () => {
  const classes = useStyles()
  const imageData = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "approach" } }) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid {
                originalName
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
  const imgEdges = imageData.allFile.edges.sort((a, b) =>
    a.node.name.localeCompare(b.node.name)
  )
  return (
    <Grid className={classes.container} item>
      <Typography variant="h4" className={classes.header}>
        tools & approach
      </Typography>
      <Typography className={classes.text} variant="body1">
        Being true to Agile methodology, we build applications which tackle the
        core of the business problem and can keep evolving based on stakeholder
        feedback. Our approach to development is three-pronged:
      </Typography>
      <Grid
        className={classes.contentContainer}
        container
        justify="center"
        alignItems="center"
        spacing={4}
      >
        <Grid item md={4}>
          <Card style={{ height: "100%" }}>
            <CardMedia>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Lottie
                  options={{
                    loop: true,
                    animationData: analysisData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width="30%"
                />
                <Typography
                  variant="body1"
                  style={{ marginTop: "25px", marginBottom: "10px" }}
                >
                  <strong>ANALYSIS</strong>
                </Typography>
              </Grid>
            </CardMedia>
            <CardContent style={{ background: "#F65058" }}>
              <Typography variant="body2" style={{ color: "#FFFFFF" }}>
                We place high importance in identifying the correct problem
                before attempting any solution. Each new project begins with
                multiple conversations with our customers to gain an
                understanding of the existing systems and the stakeholders. Once
                we have an overview, we work with our customers to narrow down
                an intial scope of development.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card style={{ height: "100%" }}>
            <CardMedia>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Lottie
                  options={{
                    loop: true,
                    animationData: developmentData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width="30%"
                />
                <Typography
                  variant="body1"
                  style={{ marginTop: "25px", marginBottom: "10px" }}
                >
                  <strong>DEVELOPMENT</strong>
                </Typography>
              </Grid>
            </CardMedia>
            <CardContent style={{ background: "#FBDE44" }}>
              <Typography variant="body2">
                We understand that it is near impossible to get the solution
                right on the first attempt. To overcome this, we use our
                expertise in various development and DevOps frameworks to
                identify a correct set of tools to address the problem and
                quickly build a scaffolding project which would be available all
                stakeholders to use and give feedback.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item md={4}>
          <Card style={{ height: "100%" }}>
            <CardMedia>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Lottie
                  options={{
                    loop: true,
                    animationData: iterationData,
                    rendererSettings: {
                      preserveAspectRatio: "xMidYMid slice",
                    },
                  }}
                  width="30%"
                />
                <Typography
                  variant="body1"
                  style={{ marginTop: "25px", marginBottom: "10px" }}
                >
                  <strong>ITERATION</strong>
                </Typography>
              </Grid>
            </CardMedia>
            <CardContent style={{ background: "#28334A" }}>
              <Typography variant="body2" style={{ color: "#FFFFFF" }}>
                All good products are evolving projects responding continuously
                to the stakeholder feedback and demands for new features.
                Keeping this in mind, we architect our projects to be modular
                and be extensible to accomodate new features. This means our
                products begin a small core with strong foundations and keep
                evolving with incremental changes.
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Typography className={classes.text} variant="body1">
        To achieve this, we bring expertise from different platforms to ensure
        the best solution on multiple dimensions including features, pricing and
        security. While we can deliver in any custom environment, these are some
        of the tools and frameworks we specialize in
      </Typography>
      <Grid className={classes.toolsContainer} container spacing={8}>
        {imgEdges.map(i => (
          <Grid item md={2} sm={4} xs={6} key={i.node.id}>
            <Img
              className={classes.image}
              imgStyle={{
                height: "100%",
                // objectFit: "contain",
              }}
              fluid={i.node.childImageSharp.fluid}
              alt="team"
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}

export default Approach
