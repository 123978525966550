import React from "react"
import { Typography, Grid, makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: "20px",
  },
  text: {
    marginTop: "20px",
  },
  header: {
    fontFamily: "Patua One",
  },
})
const AboutUs = () => {
  const classes = useStyles()
  return (
    <Grid className={classes.container} item>
      <Typography variant="h4" className={classes.header}>
        about us
      </Typography>
      <Typography className={classes.text}>
        We&apos;re chainworks, a team of entrepreneurs helping enterprises and
        government agencies identify potential use-cases of blockchain and build
        solutions to implement the technology. Having worked on multiple
        in-house, startup, MNC and government projects from development to
        deployment to support, we bring collectively more than 15 years
        experience in product research and development to help you frame the
        right questions to ensure a successful product launch.
      </Typography>
    </Grid>
  )
}

export default AboutUs
