import React from "react"
import {
  Typography,
  Grid,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
} from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import ShieldLock from "mdi-material-ui/ShieldLock"
import Close from "mdi-material-ui/Close"
import pdfDisclaimer from "../attachments/disclaimer.pdf"
import pdfDatapolicy from "../attachments/datapolicy.pdf"
import pdfBlockchain from "../attachments/blockchain.pdf"

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "20px",
  },
  text: {
    marginTop: "20px",
    fontWeight: "bold",
  },
  header: {
    fontFamily: "Patua One",
  },
  policyDescriptionText: {
    marginTop: 20,
  },
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 120,
  },
  policyPointsContainer: {
    marginTop: 5,
  },
  policyPoint: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginLeft: 20,
    paddingTop: 5,
  },
  policyPointBullet: {
    paddingRight: 10,
    alignSelf: "flex-start",
    fontWeight: "bold",
    fontSize: 16,
  },
  modalCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const Security = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [currentPdf, setCurrentPdf] = React.useState("")

  const POLICY_DOCUMENTS = {
    PDFDISCLAIMER: "PDFDISCLAIMER",
    PDFBLOCKCHAIN: "PDFBLOCKCHAIN",
    PDFDATAPOLICY: "PDFDATAPOLICY",
  }

  const getPdf = () => {
    switch (currentPdf) {
      case POLICY_DOCUMENTS.PDFDISCLAIMER:
        return pdfDisclaimer
      case POLICY_DOCUMENTS.PDFBLOCKCHAIN:
        return pdfBlockchain
      case POLICY_DOCUMENTS.PDFDATAPOLICY:
        return pdfDatapolicy
      default:
        return pdfDatapolicy
    }
  }

  const handleOpen = pdf => {
    setCurrentPdf(pdf)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        style={{
          marginLeft: 6,
          marginTop: 6,
        }}
      >
        <Typography variant="h4" className={classes.header}>
          data protection & security
        </Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: 15 }}>
        <Card
          className={classes.root}
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: 3,
            marginLeft: 30,
            marginRight: 30,
            marginBottom: 5,
          }}
        >
          <ShieldLock
            className={classes.cover}
            style={{
              color: "rgba(0,0,0,1)",
              fontSize: 75,
              marginTop: 50,
              alignSelf: "flex-start",
            }}
          />
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography className={classes.text}>
                We place a huge importance on data protection and application
                security. All data shared with us is subjected to a stringent
                SOP to eliminate any chance of data exposure. In addition to the
                best practices we follow, we also work with the customer&apos;s
                teams to comply with any internal standards. Here are some key
                security measures we take to guarantee a safe application
                development
              </Typography>
              <div className={classes.policyPointsContainer}>
                <div className={classes.policyPoint}>
                  <Typography className={classes.policyPointBullet}>
                    1.
                  </Typography>
                  <Typography style={{ fontSize: 16 }}>
                    All data shared is handled by one SPOC and is anonymized
                    before working with it in the dev environment
                  </Typography>
                </div>
                <div className={classes.policyPoint}>
                  <Typography className={classes.policyPointBullet}>
                    2.
                  </Typography>
                  <Typography style={{ fontSize: 16 }}>
                    Development team access to the testing environment databases
                    is strictly guarded with user role - based accesses with
                    access expiries in place
                  </Typography>
                </div>
                <div className={classes.policyPoint}>
                  <Typography className={classes.policyPointBullet}>
                    3.
                  </Typography>
                  <Typography style={{ fontSize: 16 }}>
                    Each application follows the industry standard security best
                    practices for security. We use Snyk to make sure we handle
                    vulnerabilities against the most recent vulnerability
                    database
                  </Typography>
                </div>
                <div className={classes.policyPoint}>
                  <Typography className={classes.policyPointBullet}>
                    4.
                  </Typography>
                  <Typography style={{ fontSize: 16 }}>
                    Our applications are submitted to an independent third-party
                    CERT-in empaneled agency to audit for OWASP vulnerabilities
                    before deployment
                  </Typography>
                </div>

                <div className={classes.policyPoint}>
                  <Typography className={classes.policyPointBullet}>
                    5.
                  </Typography>
                  <Typography style={{ fontSize: 16 }}>
                    While we also provide a HIPAA compliant user management, we
                    also integrate (and suggest using) custom SSO logins to use
                    the customer&apos;s existing enterprise login service to
                    maximize security
                  </Typography>
                </div>
                <div className={classes.policyPoint}>
                  <Typography className={classes.policyPointBullet}>
                    6.
                  </Typography>
                  <Typography style={{ fontSize: 16 }}>
                    All our data is stored in HIPAA compliant databases and the
                    data residence location is decided by the customer
                  </Typography>
                </div>
              </div>
            </CardContent>
          </div>
        </Card>
      </Grid>
      <Grid item xs={12} className={classes.policyDescriptionText}>
        For more details, please refer to our
        <Button
          style={{ marginLeft: "10px" }}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleOpen(POLICY_DOCUMENTS.PDFDATAPOLICY)}
        >
          Data Policy
        </Button>
        <Button
          style={{ marginLeft: "10px", marginRight: "10px" }}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleOpen(POLICY_DOCUMENTS.PDFBLOCKCHAIN)}
        >
          Blockchain Policy
        </Button>
        and our
        <Button
          style={{ marginLeft: "10px", marginRight: "10px" }}
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleOpen(POLICY_DOCUMENTS.PDFDISCLAIMER)}
        >
          Disclaimer
        </Button>
        Documents
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Policy
          <IconButton
            aria-label="close"
            className={classes.modalCloseButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: "100%", height: "75vh" }}>
            <iframe
              title="policy-document"
              style={{
                width: "100%",
                height: "100%",
              }}
              src={`${getPdf()}#toolbar=0&view=FitH`}
            />
          </div>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default Security
