import React from "react"
import {
  makeStyles,
  Grid,
  Typography,
  CardContent,
  Card,
  Button,
  CardActionArea,
  CardActions,
} from "@material-ui/core"
import More from "mdi-material-ui/ChevronDoubleRight"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, navigate } from "gatsby"
import Img from "gatsby-image"
import ContactModal from "./contactModal"

const useProjectsStyles = makeStyles({
  projectCard: {
    width: "100%",
  },
  featureImage: {
    width: "100%",
    maxHeight: "150px",
  },
  clientDescription: {
    paddingTop: "10px",
  },
})

const ProjectCard = ({
  slug,
  featureImage,
  title,
  client,
  description,
  requestDemo,
}) => {
  const classes = useProjectsStyles()
  return (
    <Card className={classes.projectCard} variant="elevation">
      <CardActionArea
        onClick={() => {
          navigate(slug)
        }}
      >
        <CardContent>
          <Grid container>
            <Grid item container alignItems="center" xs={6}>
              <Img
                className={classes.featureImage}
                imgStyle={{
                  height: "100%",
                  objectFit: "contain",
                }}
                fluid={featureImage.childImageSharp.fluid}
                alt="team"
              />
            </Grid>
            <Grid
              item
              container
              xs={6}
              direction="column"
              alignItems="center"
              style={{
                minHeight: "150px",
                width: "250px",
                padding: "10px",
              }}
            >
              <Grid item>
                <Typography variant="button">
                  <strong>{title}</strong>
                </Typography>
              </Grid>
              <Grid container justify="center">
                <Typography variant="caption" style={{ paddingRight: "10px" }}>
                  <strong>Client:</strong>
                </Typography>
                <Typography variant="caption">{client}</Typography>
              </Grid>
              <Grid className={classes.clientDescription} item>
                <Typography variant="subtitle2">{description}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          disableElevation
          endIcon={<More />}
          onClick={() => {
            navigate(slug)
          }}
        >
          Know More
        </Button>
        {requestDemo ? <ContactModal buttonText="Request Demo" /> : null}
      </CardActions>
    </Card>
  )
}
ProjectCard.propTypes = {
  slug: PropTypes.string.isRequired,
  featureImage: PropTypes.objectOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  client: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  requestDemo: PropTypes.bool,
}
ProjectCard.defaultProps = {
  requestDemo: false,
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: "20px",
  },
  text: {
    marginTop: "20px",
  },
  header: {
    fontFamily: "Patua One",
  },
  featureImgContainer: {
    paddingTop: "20px",
  },
})

const Projects = () => {
  const classes = useStyles()
  const displayFileData = useStaticQuery(graphql`
    query {
      allFile(
        filter: { name: { eq: "display" } }
        sort: { fields: childMarkdownRemark___frontmatter___displayIdx }
      ) {
        edges {
          node {
            id
            fields {
              slug
            }
            childMarkdownRemark {
              frontmatter {
                client
                title
                description
                requestDemo
                featureImage {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  const fileEdges = displayFileData.allFile.edges
  return (
    <Grid className={classes.container} item>
      <Typography variant="h4" className={classes.header}>
        projects
      </Typography>
      <Typography className={classes.text}>
        Selected projects from our portfolio
      </Typography>
      <Grid className={classes.featureImgContainer} container spacing={3}>
        {fileEdges.map(
          ({
            node: {
              id,
              fields: { slug },
              childMarkdownRemark: {
                frontmatter: {
                  client,
                  title,
                  description,
                  featureImage,
                  requestDemo,
                },
              },
            },
          }) => {
            return (
              <Grid
                key={id}
                sm={12}
                md={6}
                style={{
                  marginTop: "10px",
                }}
                container
                item
                justify="center"
              >
                <ProjectCard
                  slug={slug}
                  featureImage={featureImage}
                  title={title}
                  client={client}
                  description={description}
                  requestDemo={requestDemo}
                />
              </Grid>
            )
          }
        )}
      </Grid>
    </Grid>
  )
}

export default Projects
