import React from "react"
import {
  Typography,
  Grid,
  makeStyles,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton,
} from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import ShieldLock from "mdi-material-ui/ShieldLock"
import Close from "mdi-material-ui/Close"
import pdfDisclaimer from "../attachments/disclaimer.pdf"
import pdfDatapolicy from "../attachments/datapolicy.pdf"
import pdfBlockchain from "../attachments/blockchain.pdf"
import annualReport21 from "../attachments/annualReport21.pdf"
import annualReport22 from "../attachments/annualReport22.pdf"
import annualReport23 from "../attachments/annualReport23.pdf"


const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    padding: "20px",
  },
  text: {
    marginTop: "20px",
    fontWeight: "bold",
  },
  header: {
    fontFamily: "Patua One",
  },
  policyDescriptionText: {
    marginTop: 20,
  },
  root: {
    display: "flex",
  },
  details: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    flex: "1 0 auto",
  },
  cover: {
    width: 120,
  },
  policyPointsContainer: {
    marginTop: 5,
  },
  policyPoint: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    marginLeft: 20,
    paddingTop: 5,
  },
  policyPointBullet: {
    paddingRight: 10,
    alignSelf: "flex-start",
    fontWeight: "bold",
    fontSize: 16,
  },
  modalCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}))

const Security = () => {
  const classes = useStyles()
  const [open, setOpen] = React.useState(false)
  const [currentPdf, setCurrentPdf] = React.useState("")

  const POLICY_DOCUMENTS = {
    PDFDISCLAIMER: "PDFDISCLAIMER",
    PDFBLOCKCHAIN: "PDFBLOCKCHAIN",
    PDFDATAPOLICY: "PDFDATAPOLICY",
  }

  const getPdf = () => {
    switch (currentPdf) {
      case POLICY_DOCUMENTS.PDFDISCLAIMER:
        return sample
      case POLICY_DOCUMENTS.PDFBLOCKCHAIN:
        return sample
      case POLICY_DOCUMENTS.PDFDATAPOLICY:
        return sample
      default:
        return pdfDatapolicy
    }
  }

  const handleOpen = pdf => {
    setCurrentPdf(pdf)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }
  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        style={{
          marginLeft: 6,
          marginTop: 6,
        }}
      >
        <Typography variant="h4" className={classes.header}>
          Annual Reports
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.policyDescriptionText}>
        <a
          href={annualReport21}
          download="Annual_Report_2020-21"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            style={{ marginLeft: "10px" }}
            variant="outlined"
            color="primary"
            size="small"
            // onClick={() => handleOpen(POLICY_DOCUMENTS.PDFDATAPOLICY)}
          >
            Annual Report (2020-2021)
          </Button>
        </a>
      </Grid>
      <Grid item xs={12} className={classes.policyDescriptionText}>
        <a
          href={annualReport22}
          download="Annual_Report_2021-22"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            style={{ marginLeft: "10px" }}
            variant="outlined"
            color="primary"
            size="small"
            // onClick={() => handleOpen(POLICY_DOCUMENTS.PDFDATAPOLICY)}
          >
            Annual Report (2021-2022)
          </Button>
        </a>
      </Grid>
      <Grid item xs={12} className={classes.policyDescriptionText}>
        <a
          href={annualReport23}
          download="Annual_Report_2022-23"
          target="_blank"
          rel="noreferrer"
        >
          <Button
            style={{ marginLeft: "10px" }}
            variant="outlined"
            color="primary"
            size="small"
            // onClick={() => handleOpen(POLICY_DOCUMENTS.PDFDATAPOLICY)}
          >
            Annual Report (2022-2023)
          </Button>
        </a>
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
        maxWidth="lg"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          Policy
          <IconButton
            aria-label="close"
            className={classes.modalCloseButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <div style={{ width: "100%", height: "75vh" }}>
            <iframe
              title="policy-document"
              style={{
                width: "100%",
                height: "100%",
              }}
              src={`${getPdf()}#toolbar=0&view=FitH`}
            />
          </div>
        </DialogContent>
      </Dialog>
    </Grid>
  )
}

export default Security
