import React from "react"
import Color from "color"
import PropType from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Linkedin from "mdi-material-ui/Linkedin"
import {
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  makeStyles,
  IconButton,
} from "@material-ui/core"

const useFoundersStyles = makeStyles({
  founderImgContainer: {
    position: "relative",
    "&:hover": {
      "& div[id^=overlay]": {
        height: "100%",
      },
    },
  },
  founderImg: {
    height: "250px",
  },
  imgOverlay: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgb(0,0,0, 0.5)",
    color: "white",
    overflow: "hidden",
    width: "100%",
    height: 0,
    transition: ".5s ease",
  },
})

const FounderCard = ({ name, position, experiences, image, linkedinUrl }) => {
  const classes = useFoundersStyles()
  return (
    <Card>
      <CardMedia className={classes.founderImgContainer} title={name}>
        <Img
          className={classes.founderImg}
          imgStyle={{
            objectPosition: "50% 20%",
          }}
          fluid={image.node.fluid}
          alt={name}
        />
        <Grid
          container
          id={`overlay_${name}`}
          className={classes.imgOverlay}
          justify="center"
          alignItems="center"
        >
          <IconButton
            aria-label="linkedin"
            target="_blank"
            rel="noopener"
            href={linkedinUrl}
            style={{ color: "#FFFFFF" }}
          >
            <Linkedin fontSize="large" />
          </IconButton>
        </Grid>
      </CardMedia>
      <CardContent>
        <Grid
          className={classes.founderContent}
          container
          direction="column"
          justify="center"
          alignItems="center"
        >
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="subtitle2">
            <strong>{position}</strong>
          </Typography>
          {experiences.map(e => (
            <Typography key={e} variant="caption">
              {e}
            </Typography>
          ))}
        </Grid>
      </CardContent>
    </Card>
  )
}
FounderCard.propTypes = {
  name: PropType.string.isRequired,
  position: PropType.string.isRequired,
  experiences: PropType.arrayOf(PropType.string).isRequired,
  image: PropType.objectOf(PropType.any).isRequired,
  linkedinUrl: PropType.string.isRequired,
}

const useTeamStyles = makeStyles({
  teamCard: ({ color }) => ({
    borderRadius: 16,
    boxShadow: "none",
    transition: "0.2s",
    "&:hover": {
      transform: "scale(1.1)",
      boxShadow: `0 6px 12px 0 ${Color(color)
        .rotate(-12)
        .darken(0.2)
        .fade(0.5)}`,
    },
  }),
  teamImg: {
    height: "150px",
  },
  teamContent: ({ color }) => ({
    background: color,
    padding: "12px",
  }),
  teamText: {
    color: "#FFFFFF",
  },
})

const TeamMemberCard = ({ name, image, color, linkedinUrl }) => {
  const classes = useTeamStyles({ color })
  return (
    <Card className={classes.teamCard}>
      <CardMedia>
        <Img
          imgStyle={{
            objectPosition: "50% 20%",
          }}
          className={classes.teamImg}
          fluid={image.node.fluid}
          alt={name}
        />
      </CardMedia>
      <CardContent className={classes.teamContent}>
        <Grid container direction="row" justify="center" alignItems="center">
          <Typography
            variant="button"
            className={classes.teamText}
            gutterBottom
          >
            <strong>{name}</strong>
          </Typography>
          {linkedinUrl ? (
            <IconButton
              aria-label="linkedin"
              target="_blank"
              rel="noopener"
              href={linkedinUrl}
              style={{ color: "#FFFFFF", padding: "0px", paddingLeft: "10px" }}
            >
              <Linkedin fontSize="default" />
            </IconButton>
          ) : null}
        </Grid>
      </CardContent>
    </Card>
  )
}
TeamMemberCard.propTypes = {
  name: PropType.string.isRequired,
  color: PropType.string.isRequired,
  image: PropType.objectOf(PropType.any).isRequired,
  linkedinUrl: PropType.string,
}
TeamMemberCard.defaultProps = {
  linkedinUrl: undefined,
}

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    padding: "20px",
  },
  header: {
    fontFamily: "Patua One",
  },
  text: {
    marginTop: "20px",
  },
  cardsContainer: {
    marginTop: "20px",
  },
  founderCard: {
    marginBottom: "20px",
  },
  teamCard: {
    width: "100%",
  },
}))

export default function Team() {
  const classes = useStyles()
  const imageData = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            id
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const imgEdges = imageData.allImageSharp.edges
  return (
    <Grid className={classes.container} item>
      <Typography variant="h4" className={classes.header}>
        team
      </Typography>
      <Typography variant="body1" className={classes.text}>
        The research and product development background of our team equips us to
        better manage our projects.
      </Typography>
      <Grid
        container
        justify="space-around"
        spacing={3}
        className={classes.cardsContainer}
      >
        <Grid className={classes.founderCard} item xs={12} sm={6} md={3}>
          <FounderCard
            name="Jitendra Franciss Sadangi"
            position="CEO"
            experiences={["M.Sc Mathematics IIT Bombay"]}
            image={
              imgEdges.filter(
                e => e.node.fluid.originalName === "jitendra.jpg"
              )[0]
            }
            linkedinUrl="https://in.linkedin.com/in/jitendra-franciss-sadangi-045154a3"
          />
        </Grid>
        <Grid className={classes.founderCard} item xs={12} sm={6} md={3}>
          <FounderCard
            name="Ketan Bhokray"
            position="CTO"
            experiences={["B.Tech Aerospace Engg. IIT Bombay"]}
            image={
              imgEdges.filter(e => e.node.fluid.originalName === "ketan.jpg")[0]
            }
            linkedinUrl="https://www.linkedin.com/in/ketanbhokray"
          />
        </Grid>
        <Grid className={classes.founderCard} item xs={12} sm={6} md={3}>
          <Card>
            <FounderCard
              name="Tejasharee Gaidhani"
              position="COO"
              experiences={["M.Sc Mathematics Pune University"]}
              image={
                imgEdges.filter(
                  e => e.node.fluid.originalName === "tejashree.jpg"
                )[0]
              }
              linkedinUrl="https://in.linkedin.com/in/tejashree-makarand-gaidhani-048a18196"
            />
          </Card>
        </Grid>
      </Grid>
      <Typography variant="body1" className={classes.text}>
        Our key employees and interns whose efforts make it all possible
      </Typography>
      <Grid container spacing={3} className={classes.cardsContainer}>
        {[
          {
            name: "CHANDRAKANT JULURI",
            image: "chandrakant.jpeg",
            color: "#446B6A",
          },
          {
            name: "ABHISHEK TAKALE",
            image: "abhishek.jpeg",
            color: "#4D233D",
          },
          {
            name: "ROHIT GURUDASANI",
            image: "rohit.jpg",
            color: "#B35517",
          },
          {
            name: "CHINMAY PATIL",
            image: "chinmay.jpeg",
            color: "#6B60BD",
          },
          {
            name: "SUSHMITA KANERI",
            image: "sushmita.jpeg",
            color: "#D12E5C",
          },
          {
            name: "NIKET DOKE",
            image: "niket.jpeg",
            color: "#BB3B0E",
          },
          {
            name: "KANISHK DWIVEDI",
            image: "kanishk.jpg",
            color: "#443737",
          },
          {
            name: "TANAY NISTALA",
            image: "tanay.jpeg",
            color: "#30475E",
            linkedinUrl: "http://linkedin.com/in/tanaynistala",
          },
        ].map(member => {
          const imgEdge = imgEdges.filter(
            e => e.node.fluid.originalName === member.image
          )[0]
          return (
            <Grid
              className={classes.teamCard}
              key={member.image}
              item
              xs={12}
              sm={6}
              md={3}
            >
              <TeamMemberCard
                name={member.name}
                image={imgEdge}
                color={member.color}
                linkedinUrl={member.linkedinUrl}
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
}
