import React, { useState } from "react"
import {
  Typography,
  Grid,
  makeStyles,
  Link,
  TextField,
  withStyles,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme,
  Button,
} from "@material-ui/core"
import PhoneIcon from "mdi-material-ui/Phone"
import AtIcon from "mdi-material-ui/At"
import SendIcon from "mdi-material-ui/Send"
import MapMarkerIcon from "mdi-material-ui/MapMarker"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { useSnackbar } from "notistack"

const ValidationTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderLeftWidth: 6,
        padding: "4px !important",
      },
    },
  },
})(TextField)

const ContactForm = () => {
  const { enqueueSnackbar } = useSnackbar()

  const submitForm = async formData => {
    const url = "https://getform.io/f/cd6330ca-c449-4ae2-8982-2bb955ae030e"
    let response = {
      success: false,
    }
    try {
      const rawResponse = await fetch(url, {
        method: "POST",
        mode: "cors",
        credentials: "omit",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
      if (!rawResponse.ok) {
        throw Error(rawResponse.statusText)
      }
      response = await rawResponse.json()
    } catch (err) {
      response.success = false
    }
    return response.success
  }

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  return (
    <form
      style={{ marginTop: "30px" }}
      noValidate
      autoComplete="off"
      name="contact"
    >
      <Grid container direction="column">
        <Grid item container direction="row" spacing={3}>
          <Grid item sm={6} xs={12}>
            <ValidationTextField
              id="contactName"
              fullWidth
              label="Name"
              variant="outlined"
              value={name}
              onChange={e => {
                setName(e.target.value)
              }}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <ValidationTextField
              id="contactEmail"
              type="email"
              fullWidth
              label="Email"
              required
              variant="outlined"
              value={email}
              onChange={e => {
                setEmail(e.target.value)
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "20px" }}>
          <ValidationTextField
            id="contactMessage"
            required
            multiline
            rows={6}
            fullWidth
            label="Message"
            variant="outlined"
            value={message}
            onChange={e => {
              setMessage(e.target.value)
            }}
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          justify="center"
          style={{ marginTop: "20px" }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            style={{
              borderRadius: "500px",
            }}
            endIcon={<SendIcon />}
            onClick={() => {
              submitForm({ email, name, message })
                .then(success => {
                  if (success) {
                    setName("")
                    setEmail("")
                    setMessage("")
                    enqueueSnackbar("Thanks!", { variant: "success" })
                  } else {
                    enqueueSnackbar("Please try again", { variant: "error" })
                  }
                })
                .catch(() => {
                  enqueueSnackbar("Please try again", { variant: "error" })
                })
            }}
          >
            Send
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

const useStyles = makeStyles({
  container: {
    width: "100%",
    padding: "20px",
  },
  text: {
    marginTop: "20px",
  },
  header: {
    fontFamily: "Patua One",
  },
})
const Contact = () => {
  const classes = useStyles()
  const imageData = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            id
            fluid {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `)
  const imgEdges = imageData.allImageSharp.edges
  const waImgEdge = imgEdges.filter(
    e => e.node.fluid.originalName === "whatsapp.png"
  )[0]
  const callImgEdge = imgEdges.filter(
    e => e.node.fluid.originalName === "call.png"
  )[0]
  const mailImgEdge = imgEdges.filter(
    e => e.node.fluid.originalName === "email.png"
  )[0]
  const locImgEdge = imgEdges.filter(
    e => e.node.fluid.originalName === "location.png"
  )[0]
  const theme = useTheme()
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  return (
    <Grid className={classes.container} item>
      <Typography variant="h4" className={classes.header}>
        contact us
      </Typography>
      <Grid container direction="row">
        <Grid item sm={12} md={6}>
          <Typography className={classes.text}>
            We are happy to answer any queries relating to blockchain and
            business. Come say Hi to us &nbsp;
            <span role="img" aria-label="Wave">
              👋
            </span>
          </Typography>
          <ContactForm />
        </Grid>
        <Grid
          item
          container
          direction="column"
          justify="space-between"
          sm={12}
          md={6}
          style={{
            padding: "40px",
          }}
        >
          <Grid item container alignItems="center" justify="center">
            <List>
              <ListItem>
                <ListItemIcon>
                  <AtIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">hi@chainworks.io</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">+91 99679 76846</Typography>
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <MapMarkerIcon />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="body2">
                    M10, SINE,
                    <br />
                    IIT Bombay,
                    <br />
                    Mumbai
                  </Typography>
                </ListItemText>
              </ListItem>
            </List>
          </Grid>
          <Grid item container spacing={isSmUp ? 0 : 4} justify="space-around">
            <Grid item sm={1} xs={6}>
              <Link
                href="https://wa.link/zrgiok"
                target="_blank"
                rel="noopener"
              >
                <Img fluid={waImgEdge.node.fluid} alt="WhatsApp" />
              </Link>
            </Grid>
            <Grid item sm={1} xs={6}>
              <Link href="tel:+919967976846">
                <Img fluid={callImgEdge.node.fluid} alt="Call" />
              </Link>
            </Grid>
            <Grid item sm={1} xs={6}>
              <Link
                href="mailto:hi@chainworks.io"
                target="_blank"
                rel="noopener"
              >
                <Img fluid={mailImgEdge.node.fluid} alt="Mail" />
              </Link>
            </Grid>
            <Grid item sm={1} xs={6}>
              <Link
                href="https://goo.gl/maps/3cnez2CgvuEYBQW27"
                target="_blank"
                rel="noopener"
              >
                <Img fluid={locImgEdge.node.fluid} alt="Location" />
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Contact
