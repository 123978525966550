import React, { useState } from "react"
import Grid from "@material-ui/core/Grid"
import Menu from "mdi-material-ui/Menu"
import PropTypes from "prop-types"
import {
  Slide,
  useScrollTrigger,
  AppBar,
  Toolbar,
  Link,
  List,
  ListItemText,
  ListItem,
  Hidden,
  SwipeableDrawer,
  IconButton,
  Divider,
  makeStyles,
} from "@material-ui/core"

function HideOnScroll(props) {
  const { children } = props
  const trigger = useScrollTrigger()
  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
}

const useStyles = makeStyles(theme => ({
  toolbar: theme.mixins.toolbar,
}))

const NavBar = () => {
  const classes = useStyles()
  const navItems = [
    {
      label: "About Us",
      link: "/#aboutUs",
    },
    {
      label: "Projects",
      link: "/#projects",
    },
    {
      label: "Tools & Approach",
      link: "/#approach",
    },
    {
      label: "Team",
      link: "/#team",
    },
    {
      label: "Awards",
      link: "/#awards",
    },
    {
      label: "Reports",
      link: "/#annualReports",
    },
    {
      label: "Contact Us",
      link: "/#contactUs",
    },
  ]
  const [drawerOpen, toggleDrawer] = useState(false)
  const drawer = (
    <div
      role="presentation"
      onClick={() => toggleDrawer(false)}
      onKeyDown={() => toggleDrawer(false)}
    >
      <div className={classes.toolbar} />
      <Divider />
      <List>
        {navItems.map(navItem => (
          <Link
            key={navItem.link}
            href={navItem.link}
            color="inherit"
            variant="button"
          >
            <ListItem key={navItem.link} button divider>
              <ListItemText primary={navItem.label} />
            </ListItem>
          </Link>
        ))}
      </List>
    </div>
  )

  return (
    <>
      <HideOnScroll>
        <AppBar style={{ backgroundColor: "rgb(0, 0, 0, 0.4)" }}>
          <Toolbar>
            <Hidden mdUp>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => toggleDrawer(true)}
                edge="start"
              >
                <Menu />
              </IconButton>
              <SwipeableDrawer
                anchor="left"
                open={drawerOpen}
                onClose={() => toggleDrawer(false)}
                onOpen={() => toggleDrawer(true)}
              >
                {drawer}
              </SwipeableDrawer>
            </Hidden>
            <Hidden smDown>
              <Grid container justify="space-evenly">
                {navItems.map(item => (
                  <Grid key={item.link} item>
                    <Link href={item.link} color="inherit" variant="button">
                      {item.label}
                    </Link>
                  </Grid>
                ))}
              </Grid>
            </Hidden>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  )
}

export default NavBar
