import React from "react"
import { Grid, makeStyles, Typography, useMediaQuery } from "@material-ui/core"
import Lottie from "react-lottie"
import Wave from "react-wavify"
import blockchainData from "../images/hero/blockchain.json"

const useStyles = makeStyles(theme => ({
  wave: {
    [theme.breakpoints.up("sm")]: {
      height: "85vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "95vh",
    },
    transform: "rotate(180deg)",
  },
  titleContainer: {
    position: "absolute",
  },
  title: {
    fontSize: "3rem",
    fontWeight: 600,
    color: "#ffffff",
  },
  subTitle: {
    fontWeight: 600,
    color: "#ffffff",
  },
}))

const Hero = () => {
  const classes = useStyles()
  const isSmUp = useMediaQuery(theme => theme.breakpoints.up("sm"))
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item xs={12}>
        <Wave
          className={classes.wave}
          fill="url(#gradient)"
          paused={false}
          options={{
            height: 10,
            amplitude: 50,
            speed: 0.1,
            points: 3,
          }}
        >
          <defs>
            <linearGradient id="gradient" gradientTransform="rotate(90)">
              <stop offset="0%" stopColor="#000C40" />
              <stop offset="100%" stopColor="#1C1E1F" />
            </linearGradient>
          </defs>
        </Wave>
      </Grid>
      <Grid
        container
        item
        xs={12}
        className={classes.titleContainer}
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid container item sm={6} xs={12}>
          <Lottie
            options={{
              loop: true,
              animationData: blockchainData,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            width="60%"
          />
        </Grid>
        <Grid
          container
          item
          direction="column"
          sm={6}
          xs={12}
          alignItems={isSmUp ? "flex-start" : "center"}
        >
          <Typography variant="h1" className={classes.title}>
            chainworks
          </Typography>
          <Typography variant="body1" className={classes.subTitle}>
            Blockchain Products and Services
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Hero
